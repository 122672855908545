import React from "react";

import { Layout } from "~/components";
import { Home } from "~/views";
import "./styles.css";

const IndexPage = () => (
  <Layout pageWidth={false} title="Thumby Aviation">
    <Home />
  </Layout>
);

export default IndexPage;
